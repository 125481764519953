import React from "react";

import imgC from "../../assets/image/inner-page/png/incidences.png";
import imgP from "../../assets/image/inner-page/png/patterns-dot-green.png";
import imgH from "../../assets/image/inner-page/png/right-circlehalf-shape.png";

const Content2 = ({ className, ...rest }) => {
  const contentWidgetData = [
    {
      icon: "fa-helicopter",
      title: "Ayuda",
      text:
        "Atiende los problemas que tienen tus empleados/as de manera rápida y eficaz.",
      color: "blue-3",
    },
    {
      icon: "fa-heart",
      title: "Confianza",
      text: "Mejora la confianza con la empresa.",
      color: "red",
    },
    {
      icon: "fa-comment",
      title: "Comunicación",
      text:
        "Habla en tiempo real con tu empleado/a para una mejor comprensión.",
      color: "buttercup",
    },
    {
      icon: "fa-paperclip",
      title: "Documentos",
      text:
        "Adjunta videos o documentos para una mejor resolución de las incidencias.",
      color: "dark-green",
    },
  ];
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Left */}
            <div
              className="col-xl-4 col-lg-5 col-md-6 col-xs-8 order-2 order-md-1"
              data-aos="fade-right"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <h2 className="font-size-10">Una incidencia, un chat</h2>
              <ul className="list-unstyled mt-9 mt-lg-14">
                {contentWidgetData.map(
                  ({ icon, title, text, color }, index) => {
                    return (
                      <li
                        className="media align-items-center mb-12"
                        key={index}
                      >
                        <div className="border square-83 rounded-10 mr-9">
                          <span className={`text-${color} font-size-9`}>
                            <i className={`fa ${icon}`} />
                          </span>
                        </div>
                        <div className="content">
                          <h5>{title}</h5>
                          <p className="font-size-5 line-height-28 mb-0">
                            {text}
                          </p>
                        </div>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
            {/* Left End */}
            {/* Right */}
            <div
              className="col-xl-6 offset-xl-2 col-lg-7 col-md-6 col-xs-8 order-1 order-md-2"
              data-aos="fade-left"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="l2-content-image-group-2 mb-10 mb-md-0 rounded-10">
                <div className="img-1 position-relative text-right rounded-10">
                  <img className="w-100 w-lg-auto" src={imgC} alt="" />
                  <div className="img-2">
                    <img className="w-100 opacity-3" src={imgP} alt="" />
                  </div>
                  <div className="img-3">
                    <img className="w-100 opacity-3" src={imgH} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* Right */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content2;
